@font-face {
  font-family: 'SourceSansPro-Black';

  src: local('SourceSansPro-Black'),
    url(./assets/fonts/SourceSansPro-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-BlackItalic';

  src: local('SourceSansPro-BlackItalic'),
    url(./assets/fonts/SourceSansPro-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-Bold';

  src: local('SourceSansPro-Bold'),
    url(./assets/fonts/SourceSansPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-Regular';

  src: local('SourceSansPro-Regular'),
    url(./assets/fonts/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-SemiBold';

  src: local('SourceSansPro-SemiBold'),
    url(./assets/fonts/SourceSansPro-SemiBold.ttf) format('truetype');
}

@media (min-width: 1366px) {
  .responsive-card {
    grid-template-columns: repeat(5, 1fr) !important;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .responsive-card {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .responsive-card {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .responsive-card {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .responsive-card {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (max-width: 600px) {
  .responsive-card {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media (max-width: 600px) {
  .responsive-card {
    grid-template-columns: 1fr !important;
  }

  .width-medium {
    grid-column: span 1 !important;
  }

  .width-large {
    grid-column: span 1 !important;
  }
}

canvas {
  width: 100% ;
  height: 100% ;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  display: block;
  background: url("./assets/icons/Calendar.svg") no-repeat;
  border-width: thin;
  color: #475569;
  margin-left: 10px;
}
ul li+li:before {
padding: 8px;
color: black;
content: "/\00a0";
}
/* Works on Firefox */
* {
scrollbar-width: thin;
scrollbar-color:  #2563EB;
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
width: 7px;
height: 8px;
}
*::-webkit-scrollbar-thumb {
background-color: #2563EB;
border-radius: 20px;
}
html {
overscroll-behavior-x: none;
} 
body {
overscroll-behavior-x: none;
}
